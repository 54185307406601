import React from 'react'

const TopBar = () => {
  return (
    <div id="topbar">
    <div id="logo">
        <img src="/images/logo.png" alt="logo"/>
        
    </div>

    </div>
  )
}

export default TopBar