import './App.css';
import TopBar from './components/TopBar';
import { useState } from 'react';

function ConvertUrl() {
  let DownloadUrl = '';

  document.getElementById("downloadLoading").style.visibility = "visible";

  const url = "https://api.ytmusic.org/api.php?url=" + document.getElementById("userUrl").value;
  //alert(url)

  fetch(url)
    .then((response) => {
      if (!response.ok) {
        throw Error("Unsuccessful response");
      }
      return response.json();
    })
    .then((data) => {
      //console.log(data);
      DownloadUrl = 'https://api.ytmusic.org/dl.php?file=' + data['file'];
      //alert(DownloadUrl);

      //if android
      /*if(Capacitor.getPlatform() == 'android') {
        document.getElementById("downloadBtn").onclick = function (){
          const openCapacitorSite = async () => {
            await Browser.open({ url: DownloadUrl });
          };
        };
      }
      else {
        document.getElementById('downloadBtn').href=DownloadUrl;
      }*/
        document.getElementById('downloadBtn').href=DownloadUrl;
      
    })
    .finally(() => {

      document.getElementById('downloadBtn').style.visibility='visible';
      document.getElementById('downloadLoading').style.visibility='hidden';
      
    }


    );
}

function App() {
  return (
    <div className="App">
      
      <TopBar />
      <div id="slogan">Convert Youtube videos to MP3s... Simple and easy.</div>

      <div id="convert">

        <input type="text" id="userUrl" background="red" spellCheck="false" placeholder='Enter youtube URL here'></input>
        <input type="button" value="Convert to MP3" onClick={ConvertUrl}></input>

      </div>
      <div id="download">
        <div id="downloadLoading" class="loading"></div>
        <a href="/" id="downloadBtn" download>Download MP3</a>
      </div>
      
    </div>
  );
}

export default App;
